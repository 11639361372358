import React from "react"
import { graphql } from 'gatsby'
import { TransitionMask } from '@components'
import { Grid, GridItem, } from '@components/grid'
import { PageProps } from '@types'
import { navigate } from '@reach/router'


const ThanksPage = ({data: { 
  sanityPage: {
    gridItems,
  }
}}: PageProps) => {
  setTimeout(() => navigate('/'), 1000 * 6)
  return (
    <>
      <Grid>
        {gridItems.filter(p => !!p).map((item) => (
          <GridItem key={item._key} {...item} />
        ))}
      </Grid>
      
      <TransitionMask />
    </>
  )
}


export const query = graphql`
  query ThanksPageQuery {
    sanityPage(slug: {current: {eq: "thanks"}}) {
      ...pageFields
    }
  }
`

export default ThanksPage
